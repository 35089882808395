import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '众康联美康平台管理系统',
    redirect: '/login',
  },
  {
    path: '/login',
    // name: '登录',
    meta: { title: "众康联美康平台管理系统", requireAuth: true },
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/index',
    name: '工作台',
    icon: "icon-shouye",
    meta: { title: "工作台", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/index",
        icon: "icon-shouye",
        meta: { title: "工作台", requireAuth: true },
        component: () => import('@/views/index/index.vue')
      }
    ]
  },
  {
    path: '/home',
    name: '概览',
    icon: "icon-shouye",
    meta: { title: "概览", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/home",
        // name: "首页",
        icon: "icon-shouye",
        meta: { title: "概览", requireAuth: true },
        component: () => import('@/views/home/index.vue')
      }
    ]
  },
  {
    path: '/partnerHome',
    name: '合伙人概览',
    icon: "icon-shouye",
    meta: { title: "合伙人概览", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/indexPartner",
        // name: "首页",
        icon: "icon-shouye",
        meta: { title: "合伙人概览", requireAuth: true },
        component: () => import('@/views/home/indexPartner.vue')
      }
    ]
  },
  {
    path: '/merchant',
    name: '商户管理',
    icon: "icon-shouye",
    meta: { title: "商户管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/merchant/storeReview",
        // name: "商户审核",
        icon: "icon-shouye",
        meta: { title: "商户审核", requireAuth: true },
        component: () => import('@/views/merchant/storeReview.vue')
      },
      {
        path: "/merchant/reviewDetails",
        name: "审核详情",
        icon: "icon-shouye",
        meta: { title: "审核详情", requireAuth: true },
        component: () => import('@/views/merchant/details/reviewDetails.vue'),
      },
      {
        path: "/merchant/merchantList",
        // name: "商户列表",
        icon: "icon-shouye",
        meta: { title: "商户列表", requireAuth: true },
        component: () => import('@/views/merchant/merchantList.vue')
      },
      {
        path: "/merchant/addMerchant",
        name: "商户管理",
        icon: "icon-shouye",
        meta: { title: "商户管理", requireAuth: true },
        component: () => import('@/views/merchant/details/addMerchant.vue'),
      },
      {
        path: "/merchant/categories",
        name: "主营类目",
        icon: "icon-shouye",
        meta: { title: "主营类目", requireAuth: true },
        component: () => import('@/views/merchant/categories.vue'),
      },
      {
        path: "/merchant/dataStatistics",
        name: "数据统计",
        icon: "icon-shouye",
        meta: { title: "数据统计", requireAuth: true },
        component: () => import('@/views/merchant/details/dataStatistics.vue')
      },
      {
        path: "/merchant/merchantGoodsSet",
        name: "配置自动同步",
        icon: "icon-shouye",
        meta: { title: "配置自动同步", requireAuth: true },
        component: () => import('@/views/merchant/details/merchantGoodsSet.vue')
      },
    ]
  },
  {
    path: '/storeManagement',
    name: '门店管理',
    icon: "icon-shouye",
    meta: { title: "门店管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/storeManagement/shopList",
        name: "门店列表",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "门店列表", requireAuth: true },
        component: () => import('@/views/storeManagement/shopList.vue')
      },
      {
        path: "/storeManagement/shopGoodsSet",
        name: "配置自动同步",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "配置自动同步", requireAuth: true },
        component: () => import('@/views/storeManagement/details/shopGoodsSet.vue')
      },
      {
        path: "/storeManagement/modelShop",
        name: "使用样板店",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "使用样板店", requireAuth: true },
        component: () => import('@/views/storeManagement/details/modelShop.vue')
      },
      {
        path: "/storeManagement/usageRecord",
        name: "样板店使用记录",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "样板店使用记录", requireAuth: true },
        component: () => import('@/views/storeManagement/details/usageRecord.vue')
      }
    ]
  },
  {
    path: '/user',
    name: '用户管理',
    icon: "icon-kehu",
    meta: { title: "用户管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/user/index",
        name: "用户列表",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "用户列表", requireAuth: true },
        component: () => import('@/views/user/index.vue'),
      },
      {
        path: "/user/levelIndex",
        name: "等级设置",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "等级设置", requireAuth: true },
        component: () => import('@/views/user/levelIndex.vue'),
      },
      {
        path: "/user/userDetails",
        name: "用户详情",
        icon: "icon-shouye",
        meta: { title: "用户详情", requireAuth: true },
        component: () => import('@/views/user/userDetails/index.vue'),
      },
      {
        path: "/user/levelDetalis",
        name: "等级详情",
        icon: "icon-shouye",
        meta: { title: "等级详情", requireAuth: true },
        component: () => import('@/views/user/userDetails/levelDetalis.vue'),
      }
    ]
  },
  {
    path: '/platformUser',
    name: '用户管理',
    icon: "icon-kehu",
    meta: { title: "用户管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/platformUser/index",
        name: "用户列表",
        hidden: true,
        icon: "icon-shouye",
        meta: { title: "用户列表", requireAuth: true },
        component: () => import('@/views/platformUser/index.vue'),
      },
      {
        path: "/platformUser/userDetails",
        name: "用户详情",
        icon: "icon-shouye",
        meta: { title: "用户详情", requireAuth: true },
        component: () => import('@/views/platformUser/userDetails/index.vue'),
      }
    ]
  },
  {
    path: '/order',
    name: '订单管理',
    icon: "icon-shezhi",
    meta: { title: "订单管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/order/platformList",
        name: "平台分销订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "平台分销订单", requireAuth: true },
        component: () => import('@/views/order/platformList.vue')
      },
      {
        path: "/order/storeOrder",
        name: "门店自营订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "门店自营订单", requireAuth: true },
        component: () => import('@/views/order/storeOrder.vue')
      },
      {
        path: "/order/merchantList",
        name: "商户分销订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "商户分销订单", requireAuth: true },
        component: () => import('@/views/order/merchantList.vue')
      },
      {
        path: "/order/channelList",
        name: "渠道订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "渠道订单", requireAuth: true },
        component: () => import('@/views/order/channelList.vue')
      },
      {
        path: "/order/orderList",
        name: "合伙人订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "合伙人订单", requireAuth: true },
        component: () => import('@/views/order/orderList.vue')
      },
      {
        path: "/order/orderCombinedList",
        name: "组合商品订单",
        icon: "icon-dingdanliebiao",
        meta: { title: "组合商品订单", requireAuth: true },
        component: () => import('@/views/order/orderCombinedList.vue')
      },
      {
        path: "/order/details/merchantOrderDetails",
        name: "商户分销订单详情",
        icon: "icon-shouye",
        meta: { title: "商户分销订单详情", requireAuth: true },
        component: () => import('@/views/order/details/merchantOrderDetails.vue')
      },
      {
        path: "/order/details/channelOrderDetails",
        name: "渠道商订单详情",
        icon: "icon-shouye",
        meta: { title: "渠道商订单详情", requireAuth: true },
        component: () => import('@/views/order/details/channelOrderDetails.vue')
      },
      {
        path: "/order/details/platformOrderDetails",
        name: "平台分销订单详情",
        icon: "icon-shouye",
        meta: { title: "平台分销订单详情", requireAuth: true },
        component: () => import('@/views/order/details/platformOrderDetails.vue')
      },
      {
        path: "/order/details/storeOrderDetails",
        name: "门店自营订单详情",
        icon: "icon-shouye",
        meta: { title: "门店自营订单详情", requireAuth: true },
        component: () => import('@/views/order/details/storeOrderDetails.vue')
      },
      {
        path: "/order/details/orderDetails",
        name: "合伙人订单详情",
        icon: "icon-shouye",
        meta: { title: "合伙人订单详情", requireAuth: true },
        component: () => import('@/views/order/details/orderDetails.vue')
      },
      {
        path: "/order/details/orderCombinedDetails",
        name: "组合商品订单详情",
        icon: "icon-shouye",
        meta: { title: "组合商品订单详情", requireAuth: true },
        component: () => import('@/views/order/details/orderCombinedDetails.vue')
      }
    ]
  },
  {
    path: '/after',
    name: '售后管理',
    icon: "icon-shezhi",
    meta: { title: "售后管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/after/afterProvidedOrder",
        name: "平台分销售后",
        icon: "icon-wj-shd",
        meta: { title: "平台分销售后", requireAuth: true },
        component: () => import('@/views/after/afterProvidedOrder.vue')
      },
      {
        path: "/after/afterMerchantOrder",
        name: "商户分销售后",
        icon: "icon-wj-shd",
        meta: { title: "商户分销售后", requireAuth: true },
        component: () => import('@/views/after/afterMerchantOrder.vue')
      },
      {
        path: "/after/afterStoreOrder",
        name: "门店自营售后",
        icon: "icon-wj-shd",
        meta: { title: "门店自营售后", requireAuth: true },
        component: () => import('@/views/after/afterStoreOrder.vue')
      },
      {
        path: "/after/afterChannelOrder",
        name: "渠道商售后",
        icon: "icon-wj-shd",
        meta: { title: "渠道商售后", requireAuth: true },
        component: () => import('@/views/after/afterChannelOrder.vue')
      },
      {
        path: "/after/details/providedAfterDetails",
        name: "平台分销售后详情",
        icon: "icon-shouye",
        meta: { title: "平台分销售后详情", requireAuth: true },
        component: () => import('@/views/after/details/providedAfterDetails.vue')
      },
      {
        path: "/after/details/merchantAfterDetails",
        name: "商户分销售后详情",
        icon: "icon-shouye",
        meta: { title: "商户分销售后详情", requireAuth: true },
        component: () => import('@/views/after/details/merchantAfterDetails.vue')
      },
      {
        path: "/after/details/storeAfterDetails",
        name: "门店自营售后详情",
        icon: "icon-shouye",
        meta: { title: "门店自营售后详情", requireAuth: true },
        component: () => import('@/views/after/details/storeAfterDetails.vue')
      },
      {
        path: "/after/details/channelAfterDetails",
        name: "渠道商售后详情",
        icon: "icon-shouye",
        meta: { title: "渠道商售后详情", requireAuth: true },
        component: () => import('@/views/after/details/channelAfterDetails.vue')
      }
    ]
  },
  {
    path: '/commodity',
    name: '商品管理',
    icon: "icon-shezhi",
    meta: { title: "商品管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/commodity/self",
        name: "平台供货库",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "平台供货库", requireAuth: true },
        component: () => import('@/views/commodity/selfIndex.vue')
      },
      {
        path: "/commodity/selfDetails",
        name: "查看",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "查看", requireAuth: true },
        component: () => import('@/views/commodity/selfDetails/selfDetails.vue') 
      },
      {
        path: "/commodity/selfDetails2",
        name: "查看",
        hidden: true,
        icon: "icon-wodeshangpin",
        meta: { title: "查看", requireAuth: true },
        component: () => import('@/views/commodity/selfDetails/selfDetails2.vue') 
      },
      {
        path: "/commodity/cattle",
        name: "礼包商品列表",
        icon: "icon-shangpinduibi",
        meta: { title: "礼包商品列表", requireAuth: true },
        component: () => import('@/views/commodity/cattleIndex.vue')
      },
      {
        path: "/commodity/cattleCombined",
        name: "组合商品列表",
        icon: "icon-shangpinduibi",
        meta: { title: "组合商品列表", requireAuth: true },
        component: () => import('@/views/commodity/cattleCombined.vue')
      },
      {
        path: "/commodity/addCombination",
        name: "新增/编辑礼包商品详情",
        icon: "icon-shangpinduibi",
        meta: { title: "新增/编辑礼包商品详情", requireAuth: true },
        component: () => import('@/views/commodity/addCommodity/addCombination.vue')
      },
      {
        path: "/commodity/cattleindex",
        name: "商品列表",
        icon: "icon-shangpinduibi",
        meta: { title: "商品列表", requireAuth: true },
        component: () => import('@/views/commodity/cattle.vue')
      },
      {
        path: "/commodity/addCommodity",
        name: "新增商品",
        icon: "icon-shouye",
        meta: { title: "新增商品", requireAuth: true },
        component: () => import('@/views/commodity/addCommodity/index.vue')
      },
      {
        path: "/commodity/addCommodity2",
        name: "新增商品",
        icon: "icon-shouye",
        meta: { title: "新增商品", requireAuth: true },
        component: () => import('@/views/commodity/addCommodity/index2.vue')
      },
      {
        path: "/commodity/shopClass",
        name: "商品分类",
        icon: "icon-shouye",
        meta: { title: "商品分类", requireAuth: true },
        component: () => import('@/views/commodity/shopClass.vue')
      },
      {
        path: "/commodity/paveGoods",
        name: "铺货",
        icon: "icon-shouye",
        meta: { title: "铺货", requireAuth: true },
        component: () => import('@/views/commodity/paveGoods.vue')
      },
      {
        path: "/commodity/auditList",
        name: "审核列表",
        icon: "icon-shouye",
        meta: { title: "审核列表", requireAuth: true },
        component: () => import('@/views/commodity/auditList.vue')
      }
    ]
  },
  {
    path: '/marketing',
    name: '营销管理',
    icon: "icon-shouye",
    meta: { title: "营销管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/marketing/modelShopList",
        name: "样板店列表",
        icon: "icon-shouye",
        meta: { title: "样板店列表", requireAuth: true },
        component: () => import('@/views/marketing/modelShopList.vue')
      },
      {
        path: "/marketing/modelDetails",
        name: "新增/编辑样板店",
        icon: "icon-shouye",
        meta: { title: "新增/编辑样板店", requireAuth: true },
        component: () => import('@/views/marketing/details/modelDetails.vue')
      },
      {
        path: "/marketing/distributorMerchant",
        name: "分配商户",
        icon: "icon-shouye",
        meta: { title: "分配商户", requireAuth: true },
        component: () => import('@/views/marketing/details/distributorMerchant.vue')
      },
      {
        path: "/marketing/allowanceList",
        name: "津贴活动",
        icon: "icon-shouye",
        meta: { title: "津贴活动", requireAuth: true },
        component: () => import('@/views/marketing/allowanceList.vue')
      },
      {
        path: "/marketing/allowanceDetails",
        name: "全局配置",
        icon: "icon-shouye",
        meta: { title: "全局配置", requireAuth: true },
        component: () => import('@/views/marketing/details/allowanceDetails.vue')
      },
      {
        path: "/marketing/freeList",
        name: "免单活动",
        icon: "icon-shouye",
        meta: { title: "免单活动", requireAuth: true },
        component: () => import('@/views/marketing/freeList.vue')
      },
      {
        path: "/marketing/freeDetails",
        name: "新增/编辑免单活动",
        icon: "icon-shouye",
        meta: { title: "全局配置", requireAuth: true },
        component: () => import('@/views/marketing/details/freeDetails.vue')
      },
      {
        path: "/marketing/freeStatistics",
        name: "查看明细",
        icon: "icon-shouye",
        meta: { title: "查看明细", requireAuth: true },
        component: () => import('@/views/marketing/details/freeStatistics.vue')
      },
      {
        path: "/marketing/prizeDrawList",
        name: "抽奖活动",
        icon: "icon-shouye",
        meta: { title: "抽奖活动", requireAuth: true },
        component: () => import('@/views/marketing/prizeDrawList.vue')
      },
      {
        path: "/marketing/prizeDrawDetails",
        name: "抽奖活动详情",
        icon: "icon-shouye",
        meta: { title: "抽奖活动详情", requireAuth: true },
        component: () => import('@/views/marketing/details/prizeDrawDetails.vue')
      },
      {
        path: "/marketing/prizeDrawStatics",
        name: "发券统计",
        icon: "icon-shouye",
        meta: { title: "发券统计", requireAuth: true },
        component: () => import('@/views/marketing/details/prizeDrawStatics.vue')
      },
      {
        path:"/marketing/prizeStatistics",
        name: "数据统计",
        icon: "icon-shouye",
        meta: { title: "数据统计", requireAuth: true },
        component: () => import('@/views/marketing/details/prizeStatistics.vue')
      },
      {
        path: "/marketing/prizeDrawCoupon",
        name: "通用券商品配置",
        icon: "icon-shouye",
        meta: { title: "通用券商品配置", requireAuth: true },
        component: () => import('@/views/marketing/details/prizeDrawCoupon.vue')
      },{
        path: "/marketing/activityOrderList",
        name: "活动订单列表",
        icon: "icon-shouye",
        meta: { title: "活动订单列表", requireAuth: true },
        component: () => import('@/views/marketing/activityOrderList.vue')
      },{
        path: "/marketing/activityOrderDetails",
        name: "活动订单详情",
        icon: "icon-shouye",
        meta: { title: "活动订单详情", requireAuth: true },
        component: () => import('@/views/marketing/details/activityOrderDetails.vue')
      }
    ]
  },
  {
    path: "/live",
    name: '直播管理',
    icon: "icon-shouye",
    meta: { title: "直播管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/live/liveSetup",
        name: "基本设置",
        icon: "icon-shouye",
        meta: { title: "基本设置", requireAuth: true },
        component: () => import('@/views/live/liveSetup.vue')
      },
      {
        path: "/live/anchorManage",
        name: "主播管理",
        icon: "icon-shouye",
        meta: { title: "主播管理", requireAuth: true },
        component: () => import('@/views/live/anchorManage.vue')
      },
      {
        path: "/live/anchorDetails",
        name: "新增/编辑主播",
        icon: "icon-shouye",
        meta: { title: "新增/编辑主播", requireAuth: true },
        component: () => import('@/views/live/details/anchorDetails.vue')
      },
      {
        path: "/live/studioManagement",
        name: "直播间管理",
        icon: "icon-shouye",
        meta: { title: "直播间管理", requireAuth: true },
        component: () => import('@/views/live/studioManagement.vue')
      },
      {
        path: "/live/liveShop",
        name: "直播商品",
        icon: "icon-shouye",
        meta: { title: "直播商品", requireAuth: true },
        component: () => import('@/views/live/liveShop.vue')
      },
      {
        path: "/live/LiveRecord",
        name: "直播记录",
        icon: "icon-shouye",
        meta: { title: "直播记录", requireAuth: true },
        component: () => import('@/views/live/LiveRecord.vue')
      },{
        path: "/live/LiveOrder",
        name: "直播订单",
        icon: "icon-shouye",
        meta: { title: "直播订单", requireAuth: true },
        component: () => import('@/views/live/LiveOrder.vue')
      },{
        path: "/live/liveOrderDetails",
        name: "直播订单详情",
        icon: "icon-shouye",
        meta: { title: "直播订单详情", requireAuth: true },
        component: () => import('@/views/live/details/liveOrderDetails.vue')
      }
    ],
  },
  {
    path: '/address',
    name: '物流管理',
    icon: "icon-shezhi",
    meta: { title: "物流管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/address/addressList",
        name: "地址设置",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置", requireAuth: true },
        component: () => import('@/views/address/addressList.vue')
      },
      {
        path: "/address/details/addressDetails",
        name: "地址设置详情",
        icon: "icon-zhuti_o",
        meta: { title: "地址设置详情", requireAuth: true },
        component: () => import('@/views/address/details/addressDetails.vue')
      },
      {
        path: "/address/freightTemplate",
        name: "运费模版",
        icon: "icon-zhuti_o",
        meta: { title: "运费模版", requireAuth: true },
        component: () => import('@/views/address/freightTemplate.vue')
      },
      {
        path: "/address/details/freightDetails",
        name: "运费模板详情",
        icon: "icon-zhuti_o",
        meta: { title: "运费模板详情", requireAuth: true },
        component: () => import('@/views/address/details/freightDetails.vue')
      },
    ]
  },
  {
    path: '/setup',
    name: '设置',
    icon: "icon-shezhi",
    meta: { title: "设置", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/setup/settlementSettings",
        name: "结算设置",
        icon: "icon-changyongxinxi",
        meta: { title: "结算设置", requireAuth: true },
        component: () => import('@/views/setup/settlementSettings.vue')
      },
      {
        path: "/setup/revenueSettings",
        name: "进账设置",
        icon: "icon-changyongxinxi",
        meta: { title: "进账设置", requireAuth: true },
        component: () => import('@/views/setup/revenueSettings.vue')
      },
      {
        path: "/setup/withdrawalSettings",
        name: "提现设置",
        icon: "icon-changyongxinxi",
        meta: { title: "提现设置", requireAuth: true },
        component: () => import('@/views/setup/withdrawalSettings.vue')
      },
      // {
      //   path: "/setup/parameterSettings",
      //   name: "配置设置",
      //   icon: "icon-changyongxinxi",
      //   meta: { title: "配置设置", requireAuth: true },
      //   component: () => import('@/views/setup/parameterSettings.vue')
      // },
      {
        path: "/setup/platformOrderSettings",
        name: "平台订单设置",
        icon: "icon-changyongxinxi",
        meta: { title: "平台订单设置", requireAuth: true },
        component: () => import('@/views/setup/platformOrderSettings.vue')
      },
      {
        path: "/setup/platformAfterSettings",
        name: "平台订单设置",
        icon: "icon-changyongxinxi",
        meta: { title: "平台订单设置", requireAuth: true },
        component: () => import('@/views/setup/platformAfterSettings.vue')
      },
      {
        path: "/setup/dkSettlementSettings",
        name: "货款结算设置",
        icon: "icon-changyongxinxi",
        meta: { title: "货款结算设置", requireAuth: true },
        component: () => import('@/views/setup/dkSettlementSettings.vue')
      },
      {
        path: "/setup/shopSettlementSettings",
        name: "商品分销设置",
        icon: "icon-changyongxinxi",
        meta: { title: "商品分销设置", requireAuth: true },
        component: () => import('@/views/setup/shopSettlementSettings.vue')
      },
      {
        path: "/setup/dkWithdrawalSettings",
        name: "货款提现设置",
        icon: "icon-changyongxinxi",
        meta: { title: "货款提现设置", requireAuth: true },
        component: () => import('@/views/setup/dkWithdrawalSettings.vue')
      },
      {
        path: "/setup/fxWithdrawalSettings",
        name: "分销提现设置",
        icon: "icon-changyongxinxi",
        meta: { title: "分销提现设置", requireAuth: true },
        component: () => import('@/views/setup/fxWithdrawalSettings.vue')
      },
      {
        path: "/setup/serviceSettings",
        name: "平台客服设置",
        icon: "icon-changyongxinxi",
        meta: { title: "平台客服设置", requireAuth: true },
        component: () => import('@/views/setup/serviceSettings.vue')
      },
      {
        path: "/setup/serviceSettingsH5",
        name: "客服设置",
        icon: "icon-changyongxinxi",
        meta: { title: "客服设置", requireAuth: true },
        component: () => import('@/views/setup/serviceSettingsH5.vue')
      }
    ]
  },
  {
    path: '/financial',
    name: '财务管理',
    icon: "icon-shezhi",
    meta: { title: "财务管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/financial/bonusStatistics",
        name: "分红池统计",
        icon: "icon-changyongxinxi",
        meta: { title: "分红池统计", requireAuth: true },
        component: () => import('@/views/financial/bonusStatistics.vue')
      },
      // {
      //   path: "/financial/revenueRecords",
      //   name: "收益转入记录",
      //   icon: "icon-changyongxinxi",
      //   meta: { title: "收益转入记录", requireAuth: true },
      //   component: () => import('@/views/financial/revenueRecords.vue')
      // },
      {
        path: "/financial/moneyDetails",
        name: "佣金明细",
        icon: "icon-changyongxinxi",
        meta: { title: "佣金明细", requireAuth: true },
        component: () => import('@/views/financial/moneyDetails.vue')
      },
      {
        path: "/financial/withdrawalReview",
        name: "提现审核",
        icon: "icon-changyongxinxi",
        meta: { title: "提现审核", requireAuth: true },
        component: () => import('@/views/financial/withdrawalReview.vue')
      },
      {
        path: "/financial/balance",
        name: "储蓄金管理",
        icon: "icon-changyongxinxi",
        meta: { title: "储蓄金管理", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/balance/rechargeRecords",
            name: "充值记录",
            icon: "icon-changyongxinxi",
            meta: { title: "充值记录", requireAuth: true },
            component: () => import('@/views/financial/balance/rechargeRecords.vue')
          },
          {
            path: "/financial/balance/merchantBalance",
            name: "商户余额",
            icon: "icon-changyongxinxi",
            meta: { title: "商户余额", requireAuth: true },
            component: () => import('@/views/financial/balance/merchantBalance.vue')
          },
          {
            path: "/financial/balance/balanceDetails",
            name: "明细",
            icon: "icon-changyongxinxi",
            meta: { title: "明细", requireAuth: true },
            component: () => import('@/views/financial/balance/balanceDetails.vue')
          },
          {
            path: "/financial/balance/balanceReview",
            name: "余额提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "余额提现审核", requireAuth: true },
            component: () => import('@/views/financial/balance/balanceReview.vue')
          },
        ]
      },
      
      {
        path: "/financial/merchantFx",
        name: "商户分销款",
        icon: "icon-changyongxinxi",
        meta: { title: "商户分销款", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/merchantFx/fxList",
            name: "分销列表",
            icon: "icon-changyongxinxi",
            meta: { title: "分销列表", requireAuth: true },
            component: () => import('@/views/financial/merchantFx/fxList.vue')
          },
          {
            path: "/financial/merchantFx/fxDetail",
            name: "分销款明细",
            icon: "icon-changyongxinxi",
            meta: { title: "分销款明细", requireAuth: true },
            component: () => import('@/views/financial/merchantFx/fxDetail.vue')
          },
          {
            path: "/financial/merchantFx/fxStatement",
            name: "分销对账单",
            icon: "icon-changyongxinxi",
            meta: { title: "分销对账单", requireAuth: true },
            component: () => import('@/views/financial/merchantFx/fxStatement.vue')
          },
          {
            path: "/financial/merchantFx/fxWithdrawalReview",
            name: "分销提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "分销提现审核", requireAuth: true },
            component: () => import('@/views/financial/merchantFx/fxWithdrawalReview.vue')
          }
        ]
      },
      {
        path: "/financial/storeFx",
        name: "门店分销款",
        icon: "icon-changyongxinxi",
        meta: { title: "门店分销款", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/storeFx/fxList",
            name: "分销列表",
            icon: "icon-changyongxinxi",
            meta: { title: "分销列表", requireAuth: true },
            component: () => import('@/views/financial/storeFx/fxList.vue')
          },
          {
            path: "/financial/storeFx/fxDetail",
            name: "分销款明细",
            icon: "icon-changyongxinxi",
            meta: { title: "分销款明细", requireAuth: true },
            component: () => import('@/views/financial/storeFx/fxDetail.vue')
          },
          {
            path: "/financial/storeFx/fxStatement",
            name: "分销对账单",
            icon: "icon-changyongxinxi",
            meta: { title: "分销对账单", requireAuth: true },
            component: () => import('@/views/financial/storeFx/fxStatement.vue')
          },
          {
            path: "/financial/storeFx/fxWithdrawalReview",
            name: "分销提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "分销提现审核", requireAuth: true },
            component: () => import('@/views/financial/storeFx/fxWithdrawalReview.vue')
          }
        ]
      },
      {
        path: "/financial/money",
        name: "采购金管理",
        icon: "icon-changyongxinxi",
        meta: { title: "采购金管理", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/money/merchantMoney",
            name: "商户采购金",
            icon: "icon-changyongxinxi",
            meta: { title: "商户采购金", requireAuth: true },
            component: () => import('@/views/financial/money/merchantMoney.vue')
          },
          {
            path: "/financial/money/merchantMoneyDetails",
            name: "采购金明细",
            icon: "icon-changyongxinxi",
            meta: { title: "采购金明细", requireAuth: true },
            component: () => import('@/views/financial/money/merchantMoneyDetails.vue')
          },
          {
            path: "/financial/money/moneyRecords",
            name: "充值记录",
            icon: "icon-changyongxinxi",
            meta: { title: "充值记录", requireAuth: true },
            component: () => import('@/views/financial/money/moneyRecords.vue')
          },
          {
            path: "/financial/money/moneyReview",
            name: "采购金提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "采购金提现审核", requireAuth: true },
            component: () => import('@/views/financial/money/moneyReview.vue')
          }
        ]
      },
      {
        path: "/financial/merchantPayment",
        name: "商户货款",
        icon: "icon-changyongxinxi",
        meta: { title: "商户货款", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/merchantPayment/paymentDetails",
            name: "货款明细",
            icon: "icon-changyongxinxi",
            meta: { title: "货款明细", requireAuth: true },
            component: () => import('@/views/financial/merchantPayment/paymentDetails.vue')
          },
          {
            path: "/financial/merchantPayment/paymentReview",
            name: "货款提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "货款提现审核", requireAuth: true },
            component: () => import('@/views/financial/merchantPayment/paymentReview.vue')
          },
          {
            path: "/financial/merchantPayment/statement",
            name: "对账单",
            icon: "icon-changyongxinxi",
            meta: { title: "对账单", requireAuth: true },
            component: () => import('@/views/financial/merchantPayment/statement.vue')
          }
        ]
      },
      {
        path: "/financial/storePayment",
        name: "门店货款",
        icon: "icon-changyongxinxi",
        meta: { title: "门店货款", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/storePayment/paymentDetails",
            name: "货款明细",
            icon: "icon-changyongxinxi",
            meta: { title: "货款明细", requireAuth: true },
            component: () => import('@/views/financial/storePayment/paymentDetails.vue')
          },
          {
            path: "/financial/storePayment/paymentReview",
            name: "货款提现审核",
            icon: "icon-changyongxinxi",
            meta: { title: "货款提现审核", requireAuth: true },
            component: () => import('@/views/financial/storePayment/paymentReview.vue')
          },
          {
            path: "/financial/storePayment/statement",
            name: "对账单",
            icon: "icon-changyongxinxi",
            meta: { title: "对账单", requireAuth: true },
            component: () => import('@/views/financial/storePayment/statement.vue')
          }
        ]
      },
      {
        path: "/financial/platPayment",
        name: "平台货款",
        icon: "icon-changyongxinxi",
        meta: { title: "平台货款", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/platPayment/paymentDetails",
            name: "货款明细",
            icon: "icon-changyongxinxi",
            meta: { title: "货款明细", requireAuth: true },
            component: () => import('@/views/financial/platPayment/paymentDetails.vue')
          },
          {
            path: "/financial/platPayment/payment",
            name: "货款管理",
            icon: "icon-changyongxinxi",
            meta: { title: "货款管理", requireAuth: true },
            component: () => import('@/views/financial/platPayment//payment.vue')
          },
          {
            path: "/financial/platPayment/statement",
            name: "对账单",
            icon: "icon-changyongxinxi",
            meta: { title: "对账单", requireAuth: true },
            component: () => import('@/views/financial/platPayment/statement.vue')
          }
        ]
      },
      {
        path: "/financial/serviceFee",
        name: "手续费",
        icon: "icon-changyongxinxi",
        meta: { title: "手续费", requireAuth: true },
        component: {render(c) { return c('router-view') }},
        children:[
          {
            path: "/financial/serviceFee/serviceFeeManage",
            name: "手续费管理",
            icon: "icon-changyongxinxi",
            meta: { title: "手续费管理", requireAuth: true },
            component: () => import('@/views/financial/serviceFee/serviceFeeManage.vue')
          },
          {
            path: "/financial/serviceFee/serviceFeeRecord",
            name: "手续费记录",
            icon: "icon-changyongxinxi",
            meta: { title: "手续费记录", requireAuth: true },
            component: () => import('@/views/financial/serviceFee/serviceFeeRecord.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/courseManagement',
    name: '课程管理',
    icon: "icon-shezhi",
    meta: { title: "课程管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/courseManagement/courseList",
        name: "课程列表",
        icon: "icon-changyongxinxi",
        meta: { title: "课程列表", requireAuth: true },
        component: () => import('@/views/courseManagement/courseList.vue')
      },
      {
        path: "/courseManagement/addCourse",
        name: "添加课程",
        icon: "icon-changyongxinxi",
        meta: { title: "添加课程", requireAuth: true },
        component: () => import('@/views/courseManagement/addCourse.vue')
      },
      {
        path: "/courseManagement/lecturer",
        name: "课程讲师",
        icon: "icon-changyongxinxi",
        meta: { title: "课程讲师", requireAuth: true },
        component: () => import('@/views/courseManagement/lecturer.vue')
      },
      {
        path: "/courseManagement/courseCategories",
        name: "课程类目",
        icon: "icon-changyongxinxi",
        meta: { title: "课程类目", requireAuth: true },
        component: () => import('@/views/courseManagement/courseCategories.vue')
      },
      {
        path: "/courseManagement/classTime",
        name: "课程课时",
        icon: "icon-changyongxinxi",
        meta: { title: "课程课时", requireAuth: true },
        component: () => import('@/views/courseManagement/classTime.vue')
      },
    ]
  },
  {
    path: '/courseOrder',
    name: '课程订单',
    icon: "icon-shezhi",
    meta: { title: "课程订单", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/courseOrder/courseOrderList",
        name: "订单列表",
        icon: "icon-changyongxinxi",
        meta: { title: "订单列表", requireAuth: true },
        component: () => import('@/views/courseOrder/courseOrderList.vue')
      },
      {
        path: "/courseOrder/courseOrderDetails",
        name: "订单详情",
        icon: "icon-changyongxinxi",
        meta: { title: "订单详情", requireAuth: true },
        component: () => import('@/views/courseOrder/courseOrderDetails.vue')
      },
    ]
  },
  {
    path: '/resource',
    name: '资源包管理',
    icon: "icon-shezhi",
    meta: { title: "资源包管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/resource/resourceList",
        name: "资源包列表",
        icon: "icon-changyongxinxi",
        meta: { title: "资源包列表", requireAuth: true },
        component: () => import('@/views/resource/resourceList.vue')
      }
    ]
  },
  {
    path: '/account',
    name: '账号管理',
    icon: "icon-shezhi",
    meta: { title: "账号管理", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/account/accountList",
        name: "账号列表",
        icon: "icon-changyongxinxi",
        meta: { title: "账号列表", requireAuth: true },
        component: () => import('@/views/account/accountList.vue')
      },
      {
        path: "/account/auth",
        name: "角色管理",
        icon: "icon-quanxianguanli",
        meta: { title: "角色管理", requireAuth: true },
        component: () => import('@/views/account/auth.vue')
      }
    ]
  },
  {
    path: '/pageSetup',
    name: '系统页面配置',
    icon: "icon-shezhi",
    meta: { title: "系统页面配置", requireAuth: true },
    component: Layout,
    children: [
      {
        path: "/pageSetup/platFormConfig",
        name: "平台页面配置",
        icon: "icon-quanxianguanli",
        meta: { title: "平台页面配置", requireAuth: true },
        component: () => import('@/views/pageSetup/platFormConfig.vue')
      },
      {
        path: "/pageSetup/merchantConfig",
        name: "商户页面配置",
        icon: "icon-quanxianguanli",
        meta: { title: "商户页面配置", requireAuth: true },
        component: () => import('@/views/pageSetup/merchantConfig.vue')
      },
      {
        path: "/pageSetup/storeConfig",
        name: "门店页面配置",
        icon: "icon-quanxianguanli",
        meta: { title: "门店页面配置", requireAuth: true },
        component: () => import('@/views/pageSetup/storeConfig.vue')
      }
    ]
  },
  {
    path: '*',
    component: () => import('@/views/404.vue')
  }
]

//添加以下代码--解决路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // document.title = to.meta.title;
  document.title = localStorage.getItem('plat_logo_title') || '平台管理系统';//'众康联美康平台管理系统';
  let hasToken = localStorage.getItem('hasToken');
  if(hasToken){
    next();
  }else{
    if(to.path == '/login'){
      return next();
    }else{
      next({path:'/'});
    }
  }
})

export default router
